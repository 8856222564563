<template>
  <Swiper
    :allow-touch-move="allowTouchMove"
    :auto-height="autoHeight"
    :autoplay="autoplay"
    :breakpoints="breakpoints"
    :cardsEffect="cardsEffect"
    :controller="controller"
    :effect="effect"
    :grabCursor="grabCursor"
    :initial-slide="initialSlide"
    :loop="loop"
    :modules="[Autoplay, Grid, Navigation, Pagination]"
    :navigation="navigation"
    :pagination="pagination"
    :scrollbar="scrollbar"
    :slides-per-view="slidesPerView"
    :space-between="spaceBetween"
    @slideChange="({ activeIndex }) => slideChange(activeIndex)"
    @swiper="(slider) => emits('swiper', slider)"
  >
    <SwiperSlide
      v-for="(slide, index) in slides"
      :key="slide.id"
    >
      <slot v-bind="{ slide, isActive: index === currentSlideIndex }" />
    </SwiperSlide>
  </Swiper>
</template>

<script lang="ts" setup>
import { Autoplay, Grid, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

import type { SwiperOptions } from "swiper/types";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface Props extends SwiperOptions {
  slides: any[];
}

defineProps<Props>();

const emits = defineEmits<{
  slideChange: [activeIndex: number];
  swiper: [slider];
}>();

const currentSlideIndex = ref(null);

const slideChange = (activeIndex: number) => {
  currentSlideIndex.value = activeIndex;
  emits("slideChange", activeIndex);
};
</script>

<style scoped>
:deep(.swiper-slide) {
  height: auto;
}
</style>
